export function accordion() {
  class Accordion {

    slideToggle(obj){
      	const el = this._getElement(obj.target,obj.trigger)
        if(getComputedStyle(el).display === 'none' || getComputedStyle(el).visibility === 'hidden'){
          this.slideDown(obj);
        } else {
          this.slideUp(obj);
        }
    }

    slideUp(obj){
      if (obj.trigger.classList.contains('is-open')) {
        const initValue = this._init(obj,'close');
        let animeEnd;
        if(obj.btnBottomFixed){
          animeEnd = this._onBtnBottomFixed(obj.trigger);
        }
        this._animation(
          initValue.targetEls,
          {
            before:`${initValue.height}px`,
            after:'0'
          },
          obj.animationValue,
        ).onfinish = ()=>{
          obj.trigger.style.removeProperty('pointer-events');
          initValue.targetEls.style.display = 'none'
          if(obj.btnBottomFixed){
            animeEnd();
          }
          if(obj.addFunc){
            this._addFuncCall(obj.addFunc,'close-animation-finish',initValue.targetEls,obj.trigger);
          }
        };
      }
    }
    
    slideDown(obj){
      if (!obj.trigger.classList.contains('is-open')) {
        const initValue = this._init(obj,'open');
        this._animation(
        initValue.targetEls,
        {
          before:'0',
          after:`${initValue.height}px`
        },
        obj.animationValue,
        ).onfinish = ()=>{
          obj.trigger.style.removeProperty('pointer-events');
          initValue.targetEls.style.height = 'auto';
          if(obj.addFunc){
            this._addFuncCall(obj.addFunc,'open-animation-finish',initValue.targetEls,obj.trigger);
          }
        };
      }
    }

    _init(obj,state){
      const targetEls = this._getElement(obj.target,obj.trigger);
      targetEls.style.overflow = 'hidden';
      obj.trigger.style.pointerEvents = obj.pointerEvents;
      if(obj.addFunc){
        this._addFuncCall(obj.addFunc,state,targetEls,obj.trigger);
      }
      if (state === 'open'){
        obj.trigger.classList.add('is-open');
				if(getComputedStyle(targetEls).visibility === 'hidden'){
          targetEls.style.visibility = 'visible'
          targetEls.style.display = 'none';
          targetEls.style.height = 'auto';
         }
        targetEls.style.display = obj.displayValue? obj.displayValue: 'block';
      } else if (state === 'close'){
        obj.trigger.classList.remove('is-open');
      }
      const height = targetEls.offsetHeight;
      return {
        targetEls:targetEls,
        height:height
      }
    }

    _getElement(targetName,trigger){
      let target = trigger;
      if (Array.isArray(targetName)) {
        targetName.forEach((name) => {
          target = target[name];
        });
      } else {
        target = document.querySelector(targetName);
      }
      return target;
    }

    _animation(targetEls,keyframes,animationValue = {delay:0,duration:500,easing:'linear'}){
      return targetEls.animate(
        {
          height:[keyframes.before,keyframes.after]
        },
        {
          delay: animationValue.delay? animationValue.delay:0,
          duration: animationValue.duration? animationValue.duration:500,
          easing:animationValue.easing? animationValue.easing:'linear'
        }
      )
    }

    _addFuncCall(addFunc,state,targetEls,trigger){
      addFunc( {state:state, target: targetEls, trigger: trigger });
    }

    _onBtnBottomFixed(trigger) {
      let scrollTop;
      const currentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let rect = trigger.getBoundingClientRect();//ブラウザの表示領域の左上からの相対位置
      const posTop = rect.top + currentScrollTop;
      let currentPosTop = posTop;
      let accordionReverseLoopAnime;
      let headPostionAnime;
      const pageBody = document.querySelectorAll('html,body');
      accordionReverseLoop();
      function accordionReverseLoop() {
        rect = trigger.getBoundingClientRect();
        scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        currentPosTop = rect.top + scrollTop;
        Array.prototype.forEach.call(pageBody, function (value) {
          headPositonFixed(value); //jquery animeteで行なっていた部分
        });
        accordionReverseLoopAnime = window.requestAnimationFrame(accordionReverseLoop);
      }

      function headPositonFixed(trigger) {
        headPostion();
        function headPostion() {
          trigger.scrollTo(0, currentScrollTop - (posTop - currentPosTop));
        }
        headPostionAnime = window.requestAnimationFrame(headPostion);
      }

      return function animeEnd() {
        window.cancelAnimationFrame(accordionReverseLoopAnime);
        window.cancelAnimationFrame(headPostionAnime);
      }
    }
  }
  
  return new Accordion();
}