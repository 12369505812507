export function scroll(value: { targetClassName: string; buffer?: number; pageTransition?: boolean }): void {
  // extends は継承。Event を継承した HTMLEvent を新たに作り、targetに型を与えている
  // targetの型によって変更できる。<>の中に入れる。
  interface HTMLEvent<T extends EventTarget> extends Event {
    currentTarget: T;
  }
  interface SmoothScrollArgument {
    name: string;
    buffer: number;
    pageTransition: boolean;
  }
  class SmoothScroll {
    // this.〜の型
    name: string;
    buffer: number;
    pageTransition: boolean;
    pageTransitionId: string;
    /**
     * SmoothScrollの設定値をセット
     * @constructor
     * @param {object} obj - SmoothScrollの設定値
     */
    constructor(obj: SmoothScrollArgument) {
      this.name = obj.name;
      this.buffer = obj.buffer ? obj.buffer : 0;
      this.pageTransition = obj.pageTransition ? obj.pageTransition : false;
      this.pageTransitionId = sessionStorage.getItem('pageTransitionId');
      if (this.pageTransitionId) {
        this.onPageTransition();
      } else {
        this.init();
      }
    }

    /**
     * @param {string} name - 取得するエレメント（クリックするエレメント）のclass名
     */
    init = () => {
      const buffer = this.buffer ? this.buffer : 0;
      const el = document.querySelectorAll(this.name);
      Array.prototype.forEach.call(el, (value) => {
        value.addEventListener(
          'click',
          (event: HTMLEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            const id = event.currentTarget.getAttribute('href');
            if (this.pageTransition) {
              const hrefArrey = id.split('#');
              sessionStorage.setItem('pageTransitionId', `#${hrefArrey[1]}`);
              sessionStorage.setItem('buffer', this.buffer + '');
              window.location.href = hrefArrey[0];
              return;
            }
            this.onSmoothScroll(id, buffer);
          },
          false
        );
      });
    };

    onPageTransition = () => {
      this.buffer = Number(sessionStorage.getItem('buffer'));
      sessionStorage.removeItem('pageTransitionId');
      sessionStorage.removeItem('buffer');
      window.addEventListener('load', () => {
        this.onSmoothScroll(this.pageTransitionId, this.buffer);
      });
    };

    onSmoothScroll = (id: string, buffer: number) => {
      const targetElement = document.querySelector<HTMLElement>(id);
      const rectTop = targetElement.getBoundingClientRect().top;
      const offsetTop = window.pageYOffset;
      const top = rectTop + offsetTop - buffer;
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    };
  }

  new SmoothScroll({
    name: value.targetClassName,
    buffer: value.buffer,
    pageTransition: value.pageTransition,
  });
}
