

// import * as polyfillLibrary from "./polyfill-library.js"
// polyfillLibrary();
// import 'intersection-observer';
import 'web-animations-js';
import './modernizr-webp.js'

// import { intersectionObserver } from './intersection-observer';
// import { onIntersectionObserver } from './on-intersection-observer'
// onIntersectionObserver( intersectionObserver );

import { scroll } from './smoothscroll';
scroll({
  targetClassName:'.js-smoothscroll',
  buffer: 130,
});

// accordion
import { accordion } from './accordion';

function onAccordion(accordion){
  const onAccordion = accordion();
  const triggers = document.querySelectorAll('.js-accordion-trigger');

  [...triggers].forEach((trigger=>{
    trigger.addEventListener('click',(event)=>{
      onAccordion.slideToggle(
        {
          trigger: event.currentTarget,
          target:['parentNode','nextElementSibling'],
          // displayValue:'flex',// 開く要素 displayの値
          animationValue:{
            delay:0,
            duration:300,
            easing:'ease',
          },
          pointerEvents:'none', // クリックイベントのときnoneにしておくと連続殴打禁止できる。
          addFunc: (obj) => {// 関数を加える
             if(obj.state === 'open-animation-finish'){
                obj.trigger.querySelector('.js-close-button').textContent = '閉じる';
              } else if(obj.state === 'close-animation-finish'){
                obj.trigger.querySelector('.js-close-button').textContent = '開く';
              }
          }
        }
      );
    },false);
  }));
}

onAccordion(accordion);